export const setToken = (
  access_token: string,
  refresh_token: string,
  app?: string | null
) => {
  const key = app ? `${app}_` : ''
  localStorage.setItem(`${key}access_token`, access_token)
  localStorage.setItem(`${key}refresh_token`, refresh_token)
}

export const getToken = (app?: string | null) => {
  const key = app ? `${app}_` : ''
  return [
    localStorage.getItem(`${key}access_token`),
    localStorage.getItem(`${key}refresh_token`),
  ]
}

export const clearToken = (app?: string | null) => {
  const key = app ? `${app}_` : ''
  localStorage.removeItem(`${key}access_token`)
  localStorage.removeItem(`${key}refresh_token`)
}
