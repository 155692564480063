export const enUS = {
  "{<E-mail>}¤the address of an e-mail": "Email",
  "{<Password>}¤": "Password",
  "{<Next>}¤": "Next",
  "{<Previous>}¤": "Previous",
  "Forgot your {<password>}?¤": "Forgot your password?",
  "Enter an {<E-mail>}¤": "Enter an email",
  "Enter a {<password>}¤": "Enter a password",
  "Select {MaintMaster} {System}¤": "Select system",
  "Failed to login¤": "Failed to login",
  "{<Log off>}¤": "Sign out",
  "Invalid {<username>} or {<password>}¤": "Invalid username or password",
}

export interface AuthState {
  userLanguage?: string
  language: string
  languages: LanguageMeta[] | null
  messages: Record<string, string>
  passwordMessage?: string
  systems: any[]
  loading: boolean
  submitting: boolean
}

export interface LanguageMeta {
  LegacyCode: Number
  ISOName: string
  LCID: Number
  NativeName: string
  EnglishName: string
  ParentName: string
  ShortEnglishName: string
}


