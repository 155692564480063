import {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useCallback,
} from 'react'
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  Redirect,
} from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useDispatch } from 'react-redux'
import { Form } from '@fb/form'
import Email from './Email'
import Password from './Password'
import SystemList from './SystemList'
import Language from './Language'
import { systems, login, pwLogin, signout, appRedirect } from './store/actions'
import { Grid } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { usePending } from '@mm/react-redux'
import { getToken } from './client/tokenStore'
import makeStyles from '@material-ui/core/styles/makeStyles'
import PrivacyPolicy from './PrivacyPolicy'
import MMButton from './components/MMButton'
import bg from './assets/bg.png'
import Logo from './components/Logo'

interface LoginValues {
  email: string
  password: string
}

const useStyles = makeStyles(theme => ({
  mainForm: {
    margin: 'auto',
    width: '100%',
  },
  container: {
    minWidth: 250,
    margin: '0px 16px',
    height: '100%',
  },
  app: {
    overflow: 'hidden',
    minHeight: 250,
    marginBottom: 5,
    background: 'white',
    borderRadius: 16,
  },

  logo: {
    paddingTop: 48,
    width: 219,
    margin: '0 auto 24px auto',
  },

  part: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    padding: '0 24px',
  },

  textField: {
    width: 'auto',
  },

  bottomBar: {
    display: 'flex',
    flexDirection: 'column',
    padding: '6px 24px',
    marginBottom: '12px',
    alignItems: 'baseline',
    '& spacer': {
      flex: '1 1 auto',
    },
  },

  [theme.breakpoints.up(601)]: {
    textField: {
      width: '402px !important',
    },

    container: {
      maxWidth: 450,
      margin: '0 auto',
    },

    app: {
      border: '1px solid #ddd',
      height: 'auto',
      minHeight: 350,
      boxShadow: '0px 4px 5px 0px rgba(0, 0, 0, 0.25)',
    },
  },
}))

const paths = ['/', '/signin', '/pw']

export default () => {
  const classes = useStyles()
  const isPending = usePending()
  const dispatch = useDispatch()
  const [, setDirection] = useState(0)
  const { search, ...location } = useLocation()
  const history = useHistory()
  const pathname = useRef(location.pathname)

  useEffect(
    () =>
      history.listen(location => {
        const dir =
          paths.indexOf(location.pathname) - paths.indexOf(pathname.current)
        setDirection(dir)
        pathname.current = location.pathname
      }),
    [history, location.pathname]
  )

  useEffect(() => {
    if (location.pathname === '/' && localStorage.access_token) {
      dispatch(systems.request())
    }
  }, [location.pathname, dispatch])

  const doSubmit = (_: Partial<LoginValues>, values: LoginValues) => {
    switch (location.pathname) {
      case '/signin':
        dispatch(login.request(values.email))
        break
      case '/pw':
        dispatch(
          pwLogin.request({ email: values.email, password: values.password })
        )
        break
    }
  }

  const sp = new URLSearchParams(search)
  const app = sp.get('app')

  const handleSignout = useCallback(() => {
    dispatch(signout(app ?? ''))
  }, [dispatch, app])

  const email = localStorage.email || ''
  const [access_token] = getToken(app)

  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item xs={12} style={{
        display: 'flex',
        background: `url(${bg})`,
        backgroundSize: 'cover',
        position: 'relative',
      }}>
        <Form
          initialValues={{
            email,
            password: '',
          }}
          onSubmit={doSubmit}
          className={classes.mainForm}
        >
          {({ values }) => (
            <div className={classes.container}>
              <div className={classes.app}>
                {isPending || location.pathname === '/cb' ? (
                  <LinearProgress />
                ) : (
                  <div style={{ height: '4px' }}></div>
                )}
                <div className={classes.frame}>
                  <div className={classes.logo}>
                    <Logo />
                  </div>
                  <Route path='*'>
                    {({ location }: any) => {
                      return (
                        <Switch location={location}>
                          <Route path='/signout' exact>
                            <Signout app={app} />
                          </Route>
                          <Route path='/signin' exact>
                            <Email />
                          </Route>
                          <Route path='/pw' exact>
                            {() => {
                              return !values.email ? (
                                <Redirect to={{ pathname: '/signin', search }} />
                              ) : (
                                <Password />
                              )
                            }}
                          </Route>
                          <Route path='/' exact>
                            {() => {
                              return !access_token ? (
                                <Redirect to={{ pathname: '/signin', search }} />
                              ) : app ? (
                                <AppStarter app={app} />
                              ) : (
                                <SystemList />
                              )
                            }}
                          </Route>
                          <Route path='/cb' exact></Route>
                          <Route>
                            <Redirect to={{ pathname: '/', search }} />
                          </Route>
                        </Switch>
                      )
                    }}
                  </Route>
                  <div className={classes.bottomBar}>
                    <div className={classes.spacer}></div>
                    <Route path='/' exact>
                      <MMButton onClick={handleSignout} variant='outlined' disabled={false}>
                        <FormattedMessage id='{<Log off>}¤' />
                      </MMButton>
                    </Route>
                  </div>
                </div>
              </div>
              <Language />
            </div>
          )}
        </Form>
        <PrivacyPolicy />
      </Grid>
    </Grid>

  )
}

type AppStarterProps = {
  app: string
}

const AppStarter = ({ app }: AppStarterProps) => {
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(appRedirect(app))
  }, [dispatch, app])

  return null
}

type SignoutProps = {
  app?: string | null
}

const Signout = ({ app }: SignoutProps) => {
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(signout(app ?? ''))
  }, [dispatch, app])

  return null
}
