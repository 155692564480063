import React, { useState, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import {
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  FormHelperText,
  OutlinedInput,
  Box,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Field } from '@fb/form'
import { AuthState } from './store/state'
import { usePending } from '@mm/react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import MMButton from './components/MMButton'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  part: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    padding: '0 24px',
  },

  textField: {
    width: 'auto',
  },

  margin: {
    margin: theme.spacing(1, 0),
    color: theme.palette.primary.main,
  }
}))

export default () => {
  const isPending = usePending()
  const history = useHistory()
  const passwordMessage = useSelector<AuthState, string>(
    state => state.passwordMessage ?? ''
  )
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = useCallback(() => setShowPassword(!showPassword), [
    showPassword,
  ])

  const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
  }

  const doValidate = (password: string) =>
    password ? '' : 'Enter a {<password>}¤'

  const goBack = useCallback(() => {
    history.push('/signin')
  }, [history])

  const formatMsg = useCallback((msg: string | undefined) => {
    if (!msg) {
      return msg
    }

    // Show translated message for inavlid username or password
    if (msg === 'invalid username or password') {
      return 'Invalid {<username>} or {<password>}¤'
    }

    return msg
  }, [])

  return (
    <Field<string> default='' name='password' validate={doValidate} enforceUnregister={true}>
      {({ value, onFocus, onBlur, onChange, message }) => {
        const msg = formatMsg(message || passwordMessage)
        return (
          <div className={classes.part}>
            <FormControl
              disabled={isPending}
              className={classes.margin}
              variant='outlined'>
              <InputLabel error={!!msg} htmlFor='password'>
                <FormattedMessage id='{<Password>}¤' />
              </InputLabel>
              <OutlinedInput
                id='password'
                name='password'
                labelWidth={70}
                autoFocus
                type={showPassword ? 'text' : 'password'}
                className={classes.textField}
                fullWidth={true}
                value={value}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                error={!!msg}
                autoComplete='current-password'
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      disabled={isPending}
                      aria-label='toggle password visibility'
                      onClick={toggleShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {msg ? (
                <FormHelperText error={!!msg}>
                  <FormattedMessage id={msg} />
                </FormHelperText>
              ) : null}
            </FormControl>
            <a
              href="https://portal.maintmaster.com/#/confirmresetpassword;userName=null"
              className={classes.margin}>
              <FormattedMessage id='Forgot your {<password>}?¤' />
            </a>
            <Box style={{ marginTop: 24,
              display: 'flex',
              justifyContent: 'space-between' }}>
              <MMButton disabled={isPending}
                variant='outlined'
                type='button'
                onClick={goBack}>
                <FormattedMessage id='{<Previous>}¤' />
              </MMButton>
              <MMButton disabled={isPending}>
                <FormattedMessage id='{<Next>}¤' />
              </MMButton>
            </Box>
          </div>
        )
      }}
    </Field>
  )
}