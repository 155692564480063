import { ActionType, createReducer } from 'typesafe-actions'
import { AsyncActionState } from './state'
import { inc, dec } from './actions'

export type RootType = ActionType<typeof import('./actions')>

export default createReducer<AsyncActionState, RootType>({})
.handleAction(inc, (state, action) => ({
  ...state,
  [action.payload]: (state[action.payload] || 0) + 1,
}))
.handleAction(dec, (state, action) => ({
  ...state,
  [action.payload]: (state[action.payload] || 0) - 1,
}))
