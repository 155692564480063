import { createAction } from 'typesafe-actions'
import { Values, Flags } from './types'

export const raw = createAction('@fb/form/raw')<{ name: string; raw: any }>()
export const touch = createAction('@fb/form/touch')<string>()
export const set = createAction('@fb/form/set')<{
  name: string
  raw?: any
  error?: string
}>()
export const value = createAction('@fb/form/value')<{
  name: string
  value: any
  isDirty: boolean
  raw?: any
}>()
export const init = createAction('@fb/form/init')<{
  initValues: Values
  dirty: Flags
  noDirty: number
}>()
export const reset = createAction('@fb/form/reset')<undefined>()
export const resetField = createAction('@fb/form/reset-field')<string>()
export const errors = createAction('@fb/form/errors')<{
  noErrors: number
  errors: Record<string, string>
}>()
