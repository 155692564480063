import toReactComponent from 'svgr.macro'
import React, { useState, useLayoutEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  loading: {
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridTemplateRows: 'auto',
  },
  logo: {
    alignSelf: 'center',
    justifySelf: 'center',
  }
})

const Logo = toReactComponent('./logo.svg')

export type BootstrapProps<TValue = any> = {
  children: (value: TValue) => JSX.Element
  run: () => TValue | Promise<TValue>
}

export function Bootstrap<TValue = any>({ children, run }: BootstrapProps<TValue>) {
  const styles = useStyles()
  const [loading, setLoading] = useState(true)
  const [value, setValue] = useState<any>()
  const p = useRef(undefined as any)
  useLayoutEffect(() => {
    if (!p.current) {
      p.current = Promise
        .resolve()
        .then(run)
        .then((v: any) => setValue(v))
        .finally(() => setLoading(false))
    }
  }, [loading, value, run])
  return (
    loading
      ? <div className={styles.loading}><Logo className={styles.logo} /></div>
      : children(value)
  )
}
