import { ActionType, createReducer } from 'typesafe-actions'
import * as actions from './actions'
import { FormState } from './types'

type RootType = ActionType<typeof actions>

export const init: FormState = {
  noErrors: 0,
  noDirty: 0,
  initValues: {},
  values: {},
  raw: {},
  dirty: {},
  errors: {},
  touched: {},
}

export const reducer = createReducer<FormState, RootType>(init)
  .handleAction(actions.raw, (state, { payload: { name, raw } }) => ({
    ...state,
    raw: { ...state.raw, [name]: raw },
  }))
  .handleAction(actions.touch, (state, { payload: name }) => ({
    ...state,
    touched: { ...state.touched, [name]: true },
  }))
  .handleAction(actions.set, (state, { payload: { name, raw, error } }) => ({
    ...state,
    noErrors:
      error === undefined
        ? state.noErrors
        : state.noErrors +
          (state.errors[name] ? (error ? 0 : -1) : error ? 1 : 0),
    errors:
      error === undefined
        ? state.errors
        : {
            ...state.errors,
            [name]: error,
          },
    raw: raw === undefined ? state.raw : { ...state.raw, [name]: raw },
  }))
  .handleAction(
    actions.value,
    (state, { payload: { name, value, isDirty, raw } }) => ({
      ...state,
      noErrors: state.noErrors + (state.errors[name] ? -1 : 0),
      noDirty:
        state.noDirty +
        (state.dirty[name] ? (isDirty ? 0 : -1) : isDirty ? 1 : 0),
      values: {
        ...state.values,
        [name]: value,
      },
      dirty: {
        ...state.dirty,
        [name]: isDirty,
      },
      errors: state.errors[name]
        ? { ...state.errors, [name]: '' }
        : state.errors,
      raw: raw === undefined ? state.raw : { ...state.raw, [name]: raw },
    })
  )
  .handleAction(
    actions.init,
    (state, { payload: { initValues, dirty, noDirty } }) => ({
      ...state,
      initValues,
      dirty,
      noDirty,
    })
  )
  .handleAction(actions.reset, state => ({
    ...state,
    noErrors: 0,
    noDirty: 0,
    values: {},
    dirty: {},
    raw: {},
    touched: {},
    errors: {},
  }))
  .handleAction(actions.resetField, (state, { payload: name }) => {
    const values = { ...state.values }
    const raw = { ...state.raw }
    const dirty = { ...state.dirty }
    const errors = { ...state.errors }
    delete values[name]
    delete raw[name]
    delete dirty[name]
    delete errors[name]
    return {
      ...state,
      noErrors: state.noErrors + (state.errors[name] ? -1 : 0),
      noDirty: state.noDirty + (state.dirty[name] ? -1 : 0),
      values,
      dirty,
      errors,
      raw,
    }
  })
  .handleAction(actions.errors, (state, { payload: { noErrors, errors } }) => ({
    ...state,
    noErrors,
    errors,
  }))
