import React, { ErrorInfo, useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Home from '@material-ui/icons/Home'
import Refresh from '@material-ui/icons/Refresh'
import ExitToApp from '@material-ui/icons/ExitToApp'
import CircularProgress from '@material-ui/core/CircularProgress'
import Logo from './Logo'

export interface DrWatsonProps {
  onHome: () => void
  onRefresh: () => void
  onExit: () => void
}

export class DrWatson extends React.Component<DrWatsonProps> {
  state = {
    hasError: false,
  }

  constructor (props: DrWatsonProps, context?: any) {
    super(props, context)
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      ...this.state,
      hasError: true,
    })
  }

  render () {
    return this.state.hasError ? (
      <Logo>
        <CrashMessage {...this.props} />
      </Logo>
    ) : (
      this.props.children
    )
  }
}

const CrashMessage = (props: DrWatsonProps) => {
  const [submitting, setSubmitting] = useState('')
  const doHome = useCallback(() => {
    setSubmitting('home')
    props.onHome()
  }, [])
  const doRefresh = useCallback(() => {
    setSubmitting('refresh')
    props.onRefresh()
  }, [])
  const doExit = useCallback(() => {
    setSubmitting('exit')
    props.onExit()
  }, [])

  return (
    <>
      <h4><FormattedMessage id='Whoops... something went wrong.' /></h4>
      <ButtonGroup>
        <Button disabled={!!submitting} onClick={doHome}>{ submitting === 'home' ? <CircularProgress size={24} variant='indeterminate' /> : <Home /> }</Button>
        <Button disabled={!!submitting} onClick={doRefresh}>{ submitting === 'refresh' ? <CircularProgress size={24} variant='indeterminate' /> : <Refresh /> }</Button>
        <Button disabled={!!submitting} onClick={doExit}>{ submitting === 'exit' ? <CircularProgress size={24} variant='indeterminate' /> : <ExitToApp /> }</Button>
      </ButtonGroup>
    </>
  )
}
