import React, { useCallback, useMemo } from 'react'
import * as ReactDOM from 'react-dom'
import { IntlProvider } from 'react-intl'
import { BrowserRouter as Router, useHistory } from 'react-router-dom'
import { Provider, useSelector } from 'react-redux'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider as ReactJssThemeProvider } from 'react-jss'
import * as serviceWorker from './serviceWorker'
import App from './App'
import './assets/fonts.css'
import './index.css'
import { bootstrap } from './store'
import {
  Bootstrap,
  AsyncActionProvider,
  useAsyncAction,
  createSagaMonitor,
  SagaProvider,
} from '@mm/react-redux'
import { configureStore } from '@fb/redux'
import { FormProvider } from '@fb/form'
import reducer from './store/reducer'

function AppThemed() {
  const theme = React.useMemo(() => {
    return createMuiTheme({
      palette: {
        primary: {
          main: '#459197',
          light: '#50B7C1',
          dark: '#407D80',
          contrastText: '#FFFFFF'
        },
        secondary: {
          "main": "#459197",
          "dark": "#407D80",
          "light": "#50B7C1",
          "contrastText": "#FFFFFF"
        },
        type: 'light',
      },
      typography: {
        fontFamily: 'Open Sans',
      }
    })
  }, [])

  const [language, messages] = useSelector<any, any>(s => [s.language, s.messages])

  // Special case for chinese, zh-CHS = simplified chinese - "zh" maps to intl providers simplified chinese
  const locale = useMemo(() => language === 'zh-CHS' ? 'zh' : language, [language])

  return (
    <ReactJssThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <IntlProvider locale={locale} messages={messages}>
          <FormProvider>
            <App />
          </FormProvider>
        </IntlProvider>
      </ThemeProvider>
    </ReactJssThemeProvider>
  )
}

const AppLoader = () => {
  const asyncAction = useAsyncAction()
  const history = useHistory()
  const run = useCallback(async () => {
    const [store, saga] = configureStore(reducer, {
      sagaMonitor: createSagaMonitor(asyncAction),
    })
    try {
      await bootstrap(saga, history)
    } catch {
      history.replace('/')
    }
    return { store, saga }
  }, [history, asyncAction])
  return (
    <Bootstrap run={run}>
      {({ store, saga }) => {
        return (
          <SagaProvider saga={saga}>
            <Provider store={store}>
              <AppThemed />
            </Provider>
          </SagaProvider>
        )
      }}
    </Bootstrap>
  )
}

ReactDOM.render(
  <Router>
    <AsyncActionProvider>
      <AppLoader />
    </AsyncActionProvider>
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
