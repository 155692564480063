import React, {
  createContext,
  useRef,
  useCallback,
  useContext,
  useReducer,
  useState,
  useLayoutEffect,
  useMemo,
} from 'react'

import { Values } from './types'
import { init, reducer } from './reducer'
import * as actions from './actions'

const context = createContext<FormContext>(undefined as any)
const Provider = context.Provider
export const useForm = () => useContext<FormContext>(context)

type EventHandler = (e: any) => void

const EventEmitter = (registry: Record<string, EventHandler[]> = {}) => ({
  on(type: string, handler: EventHandler) {
    registry[type] = (registry[type] || []).concat(handler)
    return () => {
      registry[type] = registry[type].filter((h) => h !== handler)
    }
  },
  emit(type: string, e: any) {
    ;(registry[type] || []).forEach((h) => h(e))
  },
})

export type FieldValue<TRaw> = {
  value: TRaw
  onChange(e: React.ChangeEvent<{ name: string; value: any }>): void
  onFocus(e: React.FocusEvent<{ name: string }>): void
  onBlur(e: React.FocusEvent<{ name: string }>): void
  commit(value: TRaw): void
  message: string
  isFocus: boolean
}

export type FormContext = {
  on(name: string, handler: EventHandler): () => void
  registerField(name: string, config: FieldConfig): void
  unregisterField(name: string, enforce: boolean): void
  getField(
    name: string,
    // this is default value <Field default={...} />
    initValue?: any,
    render?: RenderFn<any, any>
  ): FieldValue<any> | null
  onChange(e: React.ChangeEvent<{ name: string; value: any }>): void
  setInitialValues(values: Values): void
  // TODO(JEJ): rename, this does not submit, only gives back changeset and values
  submit(): Promise<[Values, Values]>
  resetField(name: string): void
  reset(): void
  isValid: boolean
  values: Values
  initValues: Values
  noDirty: number
  setValue(name: string, value: any): void
}

const noopFn = (value: any) => value
const validateFn = (values: any) => ''
const equalityFn = (left: any, right: any) => left === right
type ParseFn<TValue, TRaw> = (value: TRaw) => TValue
type RenderFn<TValue, TRaw> = (value: TValue) => TRaw
type ValidatorFn<TRaw> = (
  value: TRaw
) => string | void | Promise<string> | Promise<void>
type EqualityFn<TValue> = (left: TValue, right: TValue) => boolean

type FieldConfig<TValue = any, TRaw = TValue> = {
  default?: TValue
  parse: ParseFn<TValue, TRaw>
  render: RenderFn<TValue, TRaw>
  format: RenderFn<TValue, TRaw>
  validate: ValidatorFn<TRaw>
  equality: EqualityFn<TValue>
  enforceUnregister?: boolean
}

type FieldRegistration = FieldConfig & {
  commit(raw: any): void
}

type FieldRegistry = Record<string, FieldRegistration>

type FormProviderProps = {
  children: React.ReactNode
}

export const FormProvider = (props: FormProviderProps) => {
  const [state, dispatch] = useReducer(reducer, init)
  const stateRef = useRef(state)
  stateRef.current = state
  const focus = useRef<string>('')
  const fields = useRef<FieldRegistry>({})
  const x = useMemo(EventEmitter, [])
  const events = useRef(x)

  const on = useCallback((name: string, handler: EventHandler) => {
    return events.current.on(name, handler)
  }, [])

  const setInitialValues = useCallback(
    (initValues: Values) => {
      let noDirty = 0
      const dirty = Object.keys(fields.current).reduce((cs: any, name) => {
        const { equality, default: defaultValue } = fields.current[name]
        const initValue = initValues[name]
        const value = state.values[name]
        if (value !== undefined) {
          const prevValue = initValue === undefined ? defaultValue : initValue
          const newValue = value === undefined ? defaultValue : value
          const isDirty = !equality(prevValue, newValue)
          cs[name] = isDirty
          if (isDirty) {
            noDirty++
          }
        }
        return cs
      }, {})
      dispatch(actions.init({ initValues, dirty, noDirty }))
    },
    [state.values]
  )

  const setRaw = useCallback(
    async (name: string, raw: any, quickUpdate: boolean = false) => {
      const field = fields.current[name]
      if (!field) {
        return
      }
      const { validate, parse, equality } = field
      if (quickUpdate) {
        return actions.value({ name, value: parse(raw), isDirty: true })
      }
      const error = (await Promise.resolve(raw).then(validate)) || ''
      if (error) {
        return actions.set({ name, error })
      }
      const state = stateRef.current
      const value = parse(raw)
      const isDirty =
        name in state.initValues
          ? !equality(value, state.initValues[name])
          : true
      
      const changed =
        value === state.values[name]
          ? false
          : !state.values[name]
          ? true
          : !equality(value, state.values[name])
      if (changed) {
        events.current.emit('change', { name, value })
        return actions.value({ name, value, isDirty })
      }
    }, [])

  const onChange = useCallback(
    async (e: React.ChangeEvent<{ name: string; value: any }>) => {
      const { name, value: raw } = e.currentTarget
      // INFO(JEJ): before await, otherwise selection lost on inputs
      dispatch(actions.set({ name, raw }))
      const error = !state.errors[name]
        ? ''
        : (await Promise.resolve(raw).then(fields.current[name].validate)) || ''
      dispatch(actions.set({ name, error }))
      const action = await setRaw(name, raw, true)
      if (action) {
        dispatch(action)
      }
    },
    [state.errors, setRaw]
  )

  const setValue = useCallback(
    (name: string, value: any) => {
      dispatch(actions.value({ name, value, isDirty: true }))
    },
    [dispatch]
  )

  const doCommit = useCallback(
    async (name: string, raw: any) => {
      const action = await setRaw(name, raw)
      if (action) {
        dispatch(action)
      }
    },
    [setRaw]
  )

  const onFocus = useCallback((e: React.FocusEvent<{ name: string }>) => {
    const { name } = e.currentTarget
    focus.current = name
    dispatch(actions.touch(name))
  }, [])

  const onBlur = useCallback(
    async (e: React.FocusEvent<{ name: string }>) => {
      const { name } = e.currentTarget
      focus.current = ''

      if (state.raw[name] === undefined) {
        return
      }

      const raw = state.raw[name]
      await doCommit(name, raw)
    },
    [doCommit, state.raw]
  )

  const submit = useCallback(async () => {
    let submitState = state
    if (focus.current) {
      const name = focus.current
      if (state.raw[name] !== undefined) {
        const action = await setRaw(name, state.raw[name])
        if (action) {
          submitState = reducer(submitState, action)
          dispatch(action)
        }
      }
    }

    // validate all fields
    const pl = { noErrors: 0, errors: {} } as {
      noErrors: number
      errors: Record<string, string>
    }
    for (const name of Object.keys(fields.current)) {
      const { validate, default: defaultValue, render } = fields.current[name]
      const initValue = submitState.initValues[name]
      const raw = submitState.raw[name]
      const value = submitState.values[name]

      const rawValue =
        raw === undefined
          ? value === undefined
            ? initValue === undefined
              ? render(defaultValue)
              : render(initValue)
            : render(value)
          : raw
      const error = await validate(rawValue)
      if (error) {
        pl.noErrors++
        pl.errors[name] = error
      }
    }

    if (pl.noErrors !== 0) {
      dispatch(actions.errors(pl))
      throw Error()
    }

    if (submitState.noErrors !== 0) {
      dispatch(actions.errors(submitState))
      throw Error()
    }

    const changeset = Object.keys(fields.current).reduce((cs: Values, name) => {
      if (submitState.dirty[name]) {
        cs[name] = submitState.values[name]
      }
      return cs
    }, {})
    return [changeset, { ...state.initValues, ...submitState.values }] as [
      Values,
      Values
    ]
  }, [state, setRaw])

  const resetField = useCallback((name: string) => {
    dispatch(actions.resetField(name))
  }, [])

  const reset = useCallback(() => {
    dispatch(actions.reset())
  }, [])

  const registerField = useCallback(
    (name: string, config: FieldConfig) => {
      const { commit } = fields.current[name] || {}
      fields.current[name] = {
        ...config,
        commit: commit || ((raw: any) => doCommit(name, raw)),
      }
    },
    [doCommit]
  )

  const unregisterField = useCallback((name: string, enforce: boolean) => {
    if (enforce) {
      delete fields.current[name]
    }
  }, [])

  // initValue used for split second before field is registered...
  const getField = useCallback(
    (
      name: string,
      initValue?: any,
      render?: RenderFn<any, any>
    ): FieldValue<any> => {
      const field = fields.current[name]
      if (!field) {
        const value =
          initValue === undefined ? state.initValues[name] : initValue
        return {
          value: render === undefined ? value : render(value),
          onChange,
          onFocus,
          onBlur,
          commit: noopFn as any,
          message: '',
          isFocus: false,
        }
      }
      const isFocus = focus.current === name
      const hasRaw = name in state.raw
      const hasValue = name in state.values
      const hasError = !!state.errors[name]
      const defaultValue = () =>
        name in state.initValues ? state.initValues[name] : field.default
      const value = hasError
        ? hasRaw
          ? state.raw[name]
          : defaultValue()
        : isFocus
        ? hasRaw
          ? state.raw[name]
          : hasValue
          ? field.render(state.values[name])
          : field.render(defaultValue())
        : hasValue
        ? field.format(state.values[name])
        : field.format(defaultValue())
      const message = state.errors[name] || ''
      const commit = field.commit
      return {
        value,
        onChange,
        onFocus,
        onBlur,
        commit,
        message,
        isFocus,
      }
    },
    [state, onChange, onFocus, onBlur]
  )
  const ctx = {
    on,
    registerField,
    unregisterField,
    getField,
    onChange,
    setInitialValues,
    isValid: state.noErrors === 0,
    values: state.values,
    initValues: state.initValues,
    noDirty: state.noDirty,
    submit,
    reset,
    resetField,
    setValue,
  }
  return <Provider value={ctx}>{props.children}</Provider>
}

export type FormChildProps = {
  isValid: boolean
  values: Values
  submit: () => void
  noDirty: number
}

export type FormProps = {
  className?: string
  onSubmit(changeset: Values, values: Values): void
  onChange?(e: { name: string; value: any }): void
  initialValues?: Values
  children(props: FormChildProps): JSX.Element
}

export const Form = ({
  children,
  initialValues,
  onSubmit,
  onChange,
  className,
}: FormProps) => {
  const {
    on,
    setInitialValues,
    submit,
    reset,
    isValid,
    values,
    noDirty,
  } = useForm()
  const currentInitialValues = useRef(undefined as any)

  useLayoutEffect(() => {
    if (onChange) {
      return on('change', onChange)
    }
  }, [onChange, on])

  useLayoutEffect(() => {
    if (initialValues && currentInitialValues.current !== initialValues) {
      currentInitialValues.current = initialValues
      setInitialValues(initialValues)
    }
  }, [setInitialValues, initialValues])

  const handleSubmit = useCallback(
    async (e?: React.FormEvent) => {
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }
      try {
        const [changeset, values] = await submit()
        onSubmit(changeset, values)
      } catch (message) {
      }
    },
    [submit, onSubmit]
  )
  const handleReset = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      e.stopPropagation()
      reset()
    },
    [reset]
  )
  return (
    <form
      className={className}
      onSubmit={handleSubmit}
      onReset={handleReset}
      action='#'
      noValidate
      autoComplete='off'
    >
      {children({
        isValid,
        values: { ...initialValues, ...values },
        submit: handleSubmit,
        noDirty,
      })}
    </form>
  )
}

type Field<TRaw> = {
  value: TRaw
  onChange(e: React.ChangeEvent<{ name: string; value: TRaw }>): void
  onFocus(e: React.FocusEvent<{ name: string }>): void
  onBlur(e: React.FocusEvent<{ name: string }>): void
}

// INFO(JEJ): short-hand (worse performance, added for @mm/form compliance)
type FieldChildProps<TRaw> = {
  onFocus(): void
  onBlur(): void
  commit(value: TRaw): void
}

export type FieldProps<TValue, TRaw> = {
  name: string
  default?: TValue
  parse?: ParseFn<TValue, TRaw>
  render?: RenderFn<TValue, TRaw>
  format?: RenderFn<TValue, TRaw>
  validate?: ValidatorFn<TRaw>
  equality?: EqualityFn<TValue>
  children(props: FieldValue<TRaw> & FieldChildProps<TRaw>): JSX.Element
  enforceUnregister?: boolean
}

export function Field<TValue = any, TRaw = TValue>(
  props: FieldProps<TValue, TRaw>
) {
  const { name, children } = props
  const { registerField, unregisterField, getField } = useForm()
  const [n, set] = useState(0)
  const {
    default: defaultValue,
    parse,
    render,
    format,
    validate,
    equality,
    enforceUnregister,
  } = props
  useLayoutEffect(() => {
    set(1)
    registerField(name, {
      default: defaultValue,
      parse: parse || noopFn,
      render: render || noopFn,
      format: format || render || noopFn,
      validate: validate || validateFn,
      equality: equality || equalityFn,
      enforceUnregister: enforceUnregister || false,
    })
    return () => {
      unregisterField(name, !!enforceUnregister)
    }
  }, [
    registerField,
    unregisterField,
    name,
    defaultValue,
    parse,
    render,
    format,
    validate,
    equality,
  ])

  const field = getField(name, defaultValue, render)
  const { onBlur: onBlurFn, onFocus: onFocusFn } = field || {}
  const onFocus = useCallback(() => {
    onFocusFn && onFocusFn({ currentTarget: { name } } as any)
  }, [name, onFocusFn])
  const onBlur = useCallback(() => {
    onBlurFn && onBlurFn({ currentTarget: { name } } as any)
    set(n + 1)
  }, [n, name, onBlurFn])

  return field && children({ ...field, onFocus, onBlur })
}
