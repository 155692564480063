import { History } from 'history'
import { takeLatest, put, takeEvery } from '@fb/redux'
import { UserAgentApplication } from '@azure/msal'
import { Client } from '../client'
import {
  systems,
  login,
  ssoLogin,
  pwLogin,
  selectSystem,
  setLanguage,
  messages,
  signout,
  languages,
  appRedirect,
  userLanguage
} from './actions'
import { enUS } from './state'
import { Config } from './types'
import { clearToken, setToken, getToken } from '../client/tokenStore'

export default function* (
  history: History,
  client: Client,
  msal: UserAgentApplication,
  config: Config
) {
  yield takeEvery(appRedirect, function* ({ payload: app }) {
    if (config && config.apps && config.apps[app]) {
      const redirectUrl = config.apps[app].redirectUrl
      const [access_token, refresh_token] = getToken(app)
      window.location.href = `${redirectUrl}#access_token=${access_token}&refresh_token=${refresh_token}`
    } else {
      clearToken(app)
    }
    yield
  })

  yield takeEvery(setLanguage, function* (action) {
    localStorage.setItem('language', action.payload)
    yield put(messages.request(action.payload))
  })

  yield takeLatest(signout, function* ({ payload: app }) {
    clearToken(app)
    yield history.push(
      history.createHref({
        pathname: '/signin',
        search: history.location.search,
      })
    )
  })

  yield takeLatest(messages.request, function* (action) {
    // For some reason we cant get the english translations from wide 2, so in that case
    // use the hardcoded english translations until further notice
    if (action.payload && action.payload.toLowerCase() === 'en-us') {
      yield put(messages.success(enUS))
    } else {
      try {
        const keys = Object.keys(enUS)
        const msgs = yield client.getMessages(action.payload, keys)
        const mm = keys.reduce((m, k) => {
          if (k in msgs) {
            m[k] = msgs[k]
          }
          return m
        }, {} as any)
        yield put(messages.success({ ...enUS, ...mm }))
      } catch {
        yield put(messages.failure('error loading messages'))
      }
    }
  })

  yield takeLatest(systems.request, function* (action) {
    try {
      const r = yield client.getSystems(localStorage.access_token)
      if (r.status === 200) {
        const toVersion = (v: string) =>
          Number((v.match(/^\d+\.\d+/) || ['8.10'])[0])
        const data: { Name: string; Version: string }[] = yield r.json()
        yield put(
          systems.success(
            data.sort((a, b) => {
              const v = toVersion(b.Version) - toVersion(a.Version)
              return v === 0 ? a.Name.localeCompare(b.Name) : v < 0 ? -1 : 1
            })
          )
        )
        return
      }
      yield put(systems.failure())
    } catch {
      yield put(systems.failure())
    }
  })

  yield takeLatest(selectSystem.request, function* (action) {
    try {
      const { payload: system } = action
      const r = yield client.auth(
        client.authTokenExchange(localStorage.access_token, system.Key)
      )
      const { access_token, refresh_token } = r
      const url = yield client.getSystemUrl(
        localStorage.access_token,
        system.Id
      )
      window.location.href = `${url}#access_token=${access_token}&refresh_token=${refresh_token}`
    } catch {
      // TODO(JEJ): show error message
    }
  })

  yield takeLatest(login.request, function* (action) {
    try {
      const sso = yield client.getFederated(action.payload)
      localStorage.email = action.payload
      yield put(login.success())
      if (sso) {
        yield put(ssoLogin.request(action.payload))
      } else {
        history.push(
          history.createHref({
            pathname: '/pw',
            search: history.location.search,
          })
        )
      }
    } catch {
      // TODO(JEJ): show error message
    }
  })

  yield takeLatest(pwLogin.request, function* (action) {
    const { email, password } = action.payload
    try {
      const sp = new URLSearchParams(history.location.search)
      const app = sp.get('app')
      const { clientID, redirectUrl } =
        app && config.apps && config.apps[app]
          ? config.apps[app]
          : { clientID: config.wideClientID, redirectUrl: '' }
      const { access_token, refresh_token } = yield client.auth(
        client.authPassword(email, password, clientID)
      )
      setToken(access_token, refresh_token, app)
      yield put(pwLogin.success())
      if (app && config.apps && config.apps[app]) {
        window.location.href = `${redirectUrl}#access_token=${access_token}&refresh_token=${refresh_token}`
      }
      history.push(
        history.createHref({ pathname: '/', search: history.location.search })
      )
    } catch (e) {
      yield put(pwLogin.failure(e.errorMessage))
    }
  })

  yield takeLatest(ssoLogin.request, function* (action) {
    const sp = new URLSearchParams(history.location.search)
    const app = sp.get('app')
    clearToken(app)
    yield msal.loginRedirect({
      loginHint: action.payload,
      scopes: ['openid', 'profile'],
      prompt: 'login',
    })
  })

  yield takeLatest(languages.request, function* () {
    try {
      const m = yield client.getSupportedLanguages()
      yield put(languages.success(m))
    } catch {
      yield put(languages.failure('error loading messages'))
    }
  })

  yield takeLatest(userLanguage.request, function* () {
    try {
      if (!localStorage.access_token) {
        return put(userLanguage.failure('user is not logged in'))
      }
      const userId = yield client.getUserId(localStorage.access_token)
      const userDetails = yield client.getUserDetails(userId, localStorage.access_token)

      if (userDetails) {
        const currentUserLanguage = userDetails.Language;

        yield put(userLanguage.success(currentUserLanguage))
      }
    } catch {
      yield put(userLanguage.failure('unable to get user language'))
    }
  })
}
