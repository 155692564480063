import toReactComponent from 'svgr.macro'
import React from 'react'
import { createUseStyles } from 'react-jss'


const useStyles = createUseStyles({
  loading: {
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridTemplateRows: 'auto',
  },
  logo: {
    alignSelf: 'center',
    justifySelf: 'center',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
  }
})

const Logo = toReactComponent('./logo.svg')

export default (props: any) => {
  const classes = useStyles()
  return (
    <div className={classes.loading}>
      <div className={classes.logo}>
        <Logo />
        {props.children}
      </div>
    </div>
  )
}
