import { FormattedMessage, useIntl } from 'react-intl'
import { Box, TextField } from '@material-ui/core'

import { Field } from '@fb/form'
import classnames from 'classnames'
import { usePending } from '@mm/react-redux'

import makeStyles from '@material-ui/core/styles/makeStyles'
import MMButton from './components/MMButton'

const useStyles = makeStyles(theme => ({
  part: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    padding: '0 24px',
  },

  textField: {
    width: 'auto',
  },

  margin: {
    margin: theme.spacing(1, 0),
    color: theme.palette.primary.main,
  }
}))

const validate = (email: string) => {
  return email ? '' : 'Enter an email'
}

export default () => {
  const isPending = usePending()
  const classes = useStyles();
  const { formatMessage } = useIntl()
  return (
    <Field<string> default='' name='email' validate={validate}>
      {({ value, onFocus, onBlur, onChange, message }) => (
        <div className={classes.part}>
          <TextField
            name='email'
            variant='outlined'
            value={value}
            inputProps={{
              autoComplete: 'off',
            }}
            autoFocus
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            type='email'
            disabled={isPending}
            className={classnames(classes.margin, classes.textField)}
            label={formatMessage({ id: '{<E-mail>}¤the address of an e-mail' })}
            fullWidth={true}
            error={!!message}
            helperText={message ? <FormattedMessage id={message} /> : null}
          />
          <a
            href="https://portal.maintmaster.com/#/confirmresetpassword;userName=null"
            className={classes.margin}>
            <FormattedMessage id='Forgot your {<password>}?¤' />
          </a>
          <Box style={{ marginTop: 24, display: 'flex', justifyContent: 'flex-end' }}>
            <MMButton disabled={isPending}>
              <FormattedMessage id='{<Next>}¤' />
            </MMButton>
          </Box>
        </div>
      )}
    </Field>
  )
}