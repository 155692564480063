import { Box, Link, makeStyles } from '@material-ui/core';
import { useCallback, useMemo } from 'react';

const useStyles = makeStyles(theme => ({
  policy: {
    position: 'absolute',
    color: 'white',
    fontSize: '14px',
    width: '100%',
    textAlign: 'center',
    bottom: 5,
  },
  [theme.breakpoints.up(601)]: {
    policy: {
      bottom: 15
    }
  }
}))

const PrivacyPolicy = () => {
  const classes = useStyles()
  const privacyPolicyLink = useMemo(() => 'https://maintmaster.com/privacy-policy', [])
  const currentYear = useMemo(() => new Date().getFullYear(), [])
  const handleRedirect = useCallback(() => {
    window.location.href = privacyPolicyLink
  }, [privacyPolicyLink])

  return <Box className={classes.policy}>
    MaintMaster® {currentYear} |
    <Link href={privacyPolicyLink}
      onClick={handleRedirect}
      style={{ marginLeft: 4 }}>
      Privacy Policy
    </Link>
  </Box>
}

export default PrivacyPolicy;
