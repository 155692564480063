import React, { useCallback, useEffect, useMemo } from 'react'
import { FormControl, Select, Button, MenuItem } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { AuthState, LanguageMeta } from './store/state'
import { setLanguage, languages, userLanguage } from './store/actions'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Language from '@material-ui/icons/Language'

const useStyles = makeStyles(({
  select: {
    minWidth: 120,
  },

  langBtn: {
    color: '#fff',
    textTransform: 'none',
    fontSize: 16,
    width: '100%'
  }
}))

export default () => {
  const currentUserLanguage = useSelector<AuthState, string | undefined>(state => state.userLanguage);
  const language = useSelector<AuthState, string>(state => state.language)
  const dispatch = useDispatch()
  const classes = useStyles()
  const availableLanguages = useSelector<AuthState, LanguageMeta[] | null>(n => n.languages)

  const handleChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
    const lang = e.target.value as string
    dispatch(setLanguage(lang))
  }, [dispatch])

  useEffect(() => {
    dispatch(languages.request())
  }, [dispatch])

  useEffect(() => {
    if (localStorage.access_token) {
      if (!currentUserLanguage) {
        dispatch(userLanguage.request())
      } else {
        dispatch(setLanguage(currentUserLanguage))
      }
    }
  }, [dispatch, currentUserLanguage])

  const selectedLanguageDisplayName = useMemo(() => {
    const selectedLanguage = availableLanguages?.find(lang => lang.ISOName === language)
    return selectedLanguage?.NativeName ?? ''
  }, [availableLanguages, language])

  return <div>
    <FormControl className={classes.select} style={{ width: '100%', background: 'none' }}>
      <Select
        disableUnderline={true}
        name='language'
        variant='standard'
        value={language}
        onChange={handleChange}
        style={{ paddingRight: 0 }}
        IconComponent={() => null}
        renderValue={(_) => {
          return <Button variant='text' className={classes.langBtn}>
            <Language style={{ marginRight: 8 }} /> {selectedLanguageDisplayName}
          </Button>
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}>
        {
          availableLanguages?.map((lang) => {
            return <MenuItem key={`${lang.ISOName}_${lang.NativeName}`} value={lang.ISOName}>{lang.NativeName}</MenuItem>
          })
        }
      </Select>
    </FormControl>
  </div>
}
