import React from 'react'
import { createUseStyles } from 'react-jss'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = createUseStyles({
  container: {
    flex: '1 1 auto',
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridTemplateRows: 'auto',
    background: '#ddd'
  },
  progress: {
    justifySelf: 'center',
    alignSelf: 'center',
  },
  cancelContainer: {
    flex: '1 1 auto',
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridTemplateRows: 'auto',
  },
  cancel: {
    alignSelf: 'center',
    justifySelf: 'center',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
  },
})

export const Loading = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <CircularProgress variant='indeterminate' className={classes.progress} />
    </div>
  )
}
