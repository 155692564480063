import { History } from 'history'
import { put, take, select, SagaMiddleware } from '@fb/redux'
import { Client } from '../client'
import { UserAgentApplication, AuthResponse, AuthError } from '@azure/msal'
import rootSaga from './saga'
import { messages } from './actions'
import { Config } from './types'
import { setToken, getToken } from '../client/tokenStore'

export const baseUrl = `${window.location.protocol}//${window.location.host}`

const defaultConfig: Config = {
  wideUrl: 'https://wide2test-api.maintmaster.com',
  loginUrl: baseUrl,
  wideClientID: '5EF4791FC75447459CCFA87C3BE844FB',
  maintmasterClientID: 'CA7609A1FEA8432980BC528C5A28D19E',
  azureClientID: 'b1083ecb-2992-40d9-acac-ab4bf987b6e8',
  redirectUrl: `${baseUrl}/cb`,
  authority: 'https://login.microsoftonline.com/common',
  apps: {
    portal: {
      clientID: '',
      redirectUrl: 'http://localhost:9000/portal-app',
    }
  }
}

const getConfig = async () => {
  const r = await fetch('/config.json')
  if (r.status === 200) {
    return await r.json().then((config: object) => Object.assign(defaultConfig, config))
  }
  // TODO(JEJ): hard coded default values for dev
  return defaultConfig
}

export const bootstrap = async (saga: SagaMiddleware, history: History) => {
  const config = await getConfig()
  const client = new Client(config.wideClientID, config.maintmasterClientID, config.wideUrl, config.loginUrl)

  const msal = new UserAgentApplication({
    auth: {
      clientId: config.azureClientID,
      redirectUri: `${config.redirectUrl}${history.location.search}`,
      authority: config.authority,
      validateAuthority: false,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    }
  })

  msal.handleRedirectCallback(async (authErr: AuthError, response?: AuthResponse) => {
    if (authErr || !response) {
      window.location.href = config.loginUrl
      return
    }

    const sp = new URLSearchParams(history.location.search)
    const app = sp.get('app')
    const { clientID, redirectUrl } = app && config.apps && config.apps[app] ? config.apps[app] : { clientID: config.wideClientID, redirectUrl: '' }

    try {
      const token = response.idToken.rawIdToken
      const { access_token, refresh_token } = await client.auth(client.authSsoPassword(token, clientID))
      setToken(access_token, refresh_token, app)
    } catch(e) {
      // Failed to exchange token¤
      // TODO(JEJ): show error message: Failed to login
    }

    // INFO(JEJ): federated login successful
    if (redirectUrl) {
      const [access_token, refresh_token] = getToken(app)
      window.location.href = `${redirectUrl}#access_token=${access_token}&refresh_token=${refresh_token}`
    }
    history.replace('/')
  })

  saga.run(rootSaga, history, client, msal, config)
  await saga.run(function* () {
    const language = yield select(state => state.language)
    yield put(messages.request(`${language}`))
    yield take([messages.success, messages.failure])
  }).toPromise()
}
