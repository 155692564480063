import React, { createContext, useContext } from 'react'
import { SagaMiddleware } from '@fb/redux'

const privateSagaContext = createContext<SagaMiddleware>(null as any)

const Provider = privateSagaContext.Provider

export type SagaProviderProps = {
  saga: SagaMiddleware
  children: JSX.Element
}

export const SagaProvider = ({ saga, children }: SagaProviderProps) => {
  return (
    <Provider value={saga}>
      {children}
    </Provider>
  )
}

export const useSaga = () => useContext<SagaMiddleware>(privateSagaContext)
