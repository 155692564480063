import { makeFetch, Http, getDeviceType } from '@mm/fetch'
const deviceType = getDeviceType()

export class Client {
  private http: Http

  constructor(
    private wideClientID: string,
    private maintmasterClientID: string,
    private wideUrl: string,
    private loginUrl: string) {
      this.http = makeFetch(window, {
        clientID: wideClientID,
        tokenUrl: `${loginUrl}/api/token`,
        loginUrl: loginUrl,
      })
    }

  async getSupportedLanguages() {
    const r = await this.http.fetch(`${this.wideUrl}/api/translation/language`, { headers: {'accept': 'application/json'}})
    if (r.status === 200) {
      const result = await r.json();
      return result.Languages;
    }
    throw new Error('error loading languages')
  }

  async getUserDetails(userId : string, token: string) {
    const r = await this.http.fetch(`${this.wideUrl}/api/user/${userId}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    })
    return await r.json()
  }

  async getUserId(token: string) {
    const r = await this.http.fetch(`${this.wideUrl}/api/user/id`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    })
    return await r.json()
  }

  async getMessages(language: string, keys: string[]) {
    var headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(keys)
    };

    const r = await this.http.fetch(`${this.wideUrl}/api/translation/${language}/dict`, requestOptions)
    if (r.status === 200) {
      return await r.json()
    }
    throw new Error('error loading language')
  }

  async getFederated(email: string) {
    const r = await this.http.fetch(`${this.loginUrl}/api/user/ssouser`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(email),
    })
    return await r.json()
  }

  async auth(body: URLSearchParams) {
    const r = await this.http.fetch(`${this.loginUrl}/api/token`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: body,
    })
    const d = await r.json().then(d => d, e => ({ error: 'Failed to login' }))
    return r.status === 200 ? d : Promise.reject(d)
  }

  authPassword(email: string, password: string, clientID?: string) {
    return this.toParams({
      grant_type: 'password',
      username: email,
      password: password,
      client_id: clientID || this.wideClientID,
      device_type: deviceType,
    })
  }

  authSsoPassword(ssoToken: string, clientID?: string) {
    return this.toParams({
      grant_type: 'token_exchange',
      // username: '',
      subject_token: ssoToken,
      client_id: clientID || this.wideClientID,
      device_type: deviceType,
    })
  }

  authTokenExchange(wideToken: string, systemKey: string) {
    return this.toParams({
      grant_type: 'token_exchange',
      subject_token: wideToken,
      systemkey: systemKey,
      client_id: this.maintmasterClientID,
      device_type: deviceType,
    })
  }

  async getSystems(wideToken: string) {
    const r = await this.http.fetch(`${this.wideUrl}/api/user/system?onlyValidForLogin=true`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Authorization': `Bearer ${wideToken}`,
      },
    })
    return r
  }

  async getSystemUrl(token: string, systemID: string) {
    const r = await this.http.fetch(`${this.wideUrl}/api/system/${systemID}/url`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return r.status === 200 ? await r.json() : ''
  }

  private toParams(o: Record<string, any>) {
    return Object.keys(o).reduce((d, n) => {
      d.append(n, o[n])
      return d
    }, new URLSearchParams())
  }
}
