import { createAction, createAsyncAction } from 'typesafe-actions'
import { LanguageMeta } from './state'

export interface Token {
  access_token: string
  refresh_token: string
}

export interface SsoToken {
  idToken: string
}

export interface PwLogin {
  email: string
  password: string
}

export const appRedirect = createAction('@mm-login/app-redirect')<string>()

export const reset = createAction('@mm-login/reset')<undefined>()
export const systems = createAsyncAction(
  '@mm-login/request-systems',
  '@mm-login/success-systems',
  '@mm-login/failure-systems')<undefined, any[], undefined>()
export const submit = createAction('@mm-login/submit')<boolean>()
export const selectSystem = createAsyncAction(
  '@mm-login/request-select-system',
  '@mm-login/success-select-system',
  '@mm-login/failure-select-system')<any, any, any>()
export const login = createAsyncAction(
  '@mm-login/request-login',
  '@mm-login/success-login',
  '@mm-login/failure-login')<string, undefined, undefined>()
export const ssoLogin = createAsyncAction(
  '@mm-login/request-sso-login',
  '@mm-login/success-sso-login',
  '@mm-login/failure-sso-login')<string, SsoToken, string>()
export const pwLogin = createAsyncAction(
  '@mm-login/request-pw-login',
  '@mm-login/success-pw-login',
  '@mm-login/failure-pw-login')<PwLogin, undefined, string>()
export const signout = createAction('@mm-login/signout')<string>()
export const setLanguage = createAction('@mm-login/set-language')<string>()
export const userLanguage = createAsyncAction(
  '@mm-login/request-userlanguage',
  '@mm-login/success-userlanguage',
  '@mm-login/failure-userlanguage')<void, string, string>()
export const messages = createAsyncAction(
  '@mm-login/request-messages',
  '@mm-login/success-messages',
  '@mm-login/failure-messages')<string, Record<string, string>, string>()
export const languages = createAsyncAction(
    '@mm-login/request-languages',
    '@mm-login/success-languages',
    '@mm-login/failure-languages')<void, LanguageMeta[], string>()
