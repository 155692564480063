import React, { useEffect, useState, createContext, PropsWithChildren, useReducer, useContext, useCallback, useRef } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { ActionType, createReducer, createAction, getType, Task, SagaMiddleware, takeEvery, Saga } from '@fb/redux'
import { useSaga } from './SagaProvider'
import assert from '@fb/macros/assert.macro'

export type ActivityContext = {
  state: any
  dispatch: any
}

type AsyncAction = {
  request: any
  success: any
  failure: any
  cancel?: any
}

const activityContext = createContext<ActivityContext>(undefined as any)

const Provider = activityContext.Provider

type Commit = {
  name: string
  path: string
  url: string
  value: any
}

const actions = {
  commit: createAction('@@activity/onEnter')<Commit>()
}

type RootType = ActionType<typeof actions>

export type ActivityState = Record<string, { url: string, data: Record<string, any> }>

const reducer = createReducer<ActivityState, RootType>({})
  .handleAction(actions.commit, (state, { payload: { name, path, url, value }}) => {
    return {
      ...state,
      [path]: {
        ...state[path],
        url: url.replace(/\/$/, ''),
        data:
          state[path] && state[path].url === url.replace(/\/$/, '') ? {
            ...state[path].data,
            [name]: value
          }
          : { [name]: value },
      },
    }
  })

export const ActivityProvider = ({ children, }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(reducer, {})

  return (
    <Provider value={{ state, dispatch }}>
      {children}
    </Provider>
  )
}

export const useActivity = (name: string) => {
  const { path, url } = useRouteMatch()
  const nurl = url.replace(/\/$/, '')
  const { state, dispatch } = useContext(activityContext)
  const value = state[path] && state[path].url === nurl
    ? state[path].data[name] || emptyObj
    : emptyObj
  const commit = useCallback((value: any) => {
    dispatch(actions.commit({ name, path, url: nurl, value }))
  }, [dispatch, name, path, nurl])
  return {
    value,
    commit,
  }
}

export type Fn = (saga: SagaMiddleware) => Task
export type ActivityProps = {
  name?: string
  children: (state: Record<string, any>, commit: (value: any) => void) => JSX.Element
  on?: Saga
  fallback?: JSX.Element
  action?: AsyncAction | AsyncAction[]
}

const emptyObj = {}
export const Activity = (props: ActivityProps) => {
  return <ActivityImpl {...props} />
}

const ActivityImpl = ({ name, children, fallback, on, action }: ActivityProps) => {
  const { value, commit } = useActivity(name || '')
  const isEmpty = value === emptyObj
  const [loading, setLoading] = useState(on && isEmpty)
  const [pending, setPending] = useState(false)
  const noPending = useRef(0)
  const pendingActions = useRef<Record<string, boolean>>({})
  const saga = useSaga()

  const actions = action ? Array.isArray(action) ? action : [action] : null

  useEffect(() => {
    if (!actions) {
      return () => {}
    }

    const task = saga.run(function* () {
      const t: any = yield takeEvery(actions.map(a => a.request), function * (action: any) {
        if (!pendingActions.current[action.type]) {
          // console.log('>>>>>', action)
          ++noPending.current
          pendingActions.current[action.type] = true
        }
        setPending(true)
      })

      yield takeEvery(actions.flatMap(a => [a.success, a.failure, a.cancel].filter(a => a)), function* (action: { type: string }) {
        assert(noPending.current !== 0, `[${action.type}] noPending must not be zero! Most likely due to mismatch of action \`request\` and its \`success\`, \`failure\` or \`cancel\` opposite. Make sure async actions are balanced.`)

        const { type } = action
        const a = actions.find(a => [a.success, a.failure, a.cancel].filter(a => a).map(a => a.getType() as string).some(t => t === type))!.request
        if (pendingActions.current[a.getType()]) {
          // console.log('<<<<<', action)
          pendingActions.current[a.getType()] = false
          if (--noPending.current === 0) {
            setPending(false)
          }  
        }
      })
    })

    return () => {
      task.cancel()
    }
  }, [saga])

  useEffect(() => {
    if (!on) {
      return () => {}
    }
    let unMounted = false
    setLoading(true)
    const task = saga.run(on)
    task.toPromise().then(value => {
      if (value !== '@@redux-saga/TASK_CANCEL' && !unMounted) {
        // TODO(JEJ): figure out why this was needed with POJ...
        // commit(value)
      }
    }).finally(() => {
      if (!unMounted) {
        setLoading(false)
        noPending.current = 0
      }
    })
    return () => {
      unMounted = true
      if (task.isRunning()) {
        task.cancel()
      }
    }
  }, [on])

  return (
    loading || pending
      ? fallback || null
      : children(value, commit)
  )
}
