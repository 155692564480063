import { ActionType, createReducer } from 'typesafe-actions'
import {
  reset,
  systems,
  submit,
  setLanguage,
  messages,
  pwLogin,
  languages,
  userLanguage,
} from './actions'
import { enUS, AuthState } from './state'

export type RootType = ActionType<typeof import('./actions')>

const initState = {
  userLanguage: undefined,
  language: localStorage.getItem('language') || 'en-US',
  messages: enUS,
  languages: null,
  loading: false,
  submitting: false,
  systems: [],
}

export default createReducer<AuthState, RootType>(initState)
  .handleAction(reset, () => ({
    userLanguage: undefined,
    language: localStorage.getItem('language') || 'en-US',
    messages: {},
    loading: false,
    submitting: false,
    systems: [],
    languages: null,
  }))
  .handleAction(systems.request, (state, action) => ({
    ...state,
    loading: true,
  }))
  .handleAction(systems.success, (state, action) => ({
    ...state,
    systems: action.payload,
    loading: false,
  }))
  .handleAction(systems.failure, (state, action) => ({
    ...state,
    systems: [],
    loading: false,
  }))
  .handleAction(submit, (state, action) => ({
    ...state,
    submitting: action.payload,
  }))
  .handleAction(setLanguage, (state, action) => ({
    ...state,
    language: action.payload,
  }))
  .handleAction(messages.success, (state, action) => ({
    ...state,
    messages: action.payload,
  }))
  .handleAction(pwLogin.request, (state, action) => ({
    ...state,
    passwordMessage: '',
  }))
  .handleAction(pwLogin.failure, (state, action) => ({
    ...state,
    passwordMessage: action.payload,
  }))
  .handleAction(languages.success, (state, action) => ({
    ...state,
    languages: action.payload,
  }))
  .handleAction(userLanguage.success, (state, action) => ({
    ...state,
    userLanguage: action.payload
  }))
