import Button from '@material-ui/core/Button'
import { PropsWithChildren } from 'react'

interface MMButtonProps {
    variant?: 'text' | 'outlined' | 'contained'
    color?: 'inherit' | 'primary' | 'secondary' | 'default'
    type?: 'submit' | 'reset' | 'button'
    onClick?: () => void
    disabled: boolean
}

const MMButton = ({ children,
    variant = 'contained',
    color = 'primary',
    type = 'submit',
    onClick,
    disabled }: PropsWithChildren<MMButtonProps>) => {
    return <Button
        type={type}
        color={color}
        variant={variant}
        onClick={onClick}
        style={{
            borderRadius: '16px',
            boxShadow: 'none',
            textTransform: 'none',
            fontWeight: 600,
            fontSize: '0.875rem',
            padding: '7px 16px',
            height: 40
        }}
        disabled={disabled}>
        {children}
    </Button>
}

export default MMButton