import React, { createContext, useContext, PropsWithChildren, useReducer } from 'react'
import { Action } from '@fb/redux'
import reducer from './reducer'
import { inc, dec } from './actions'

export const createSagaMonitor = (asyncAction: AsyncActionContext) => {
  return {
    actionDispatched(action: Action) {
      const t = action.type.split('/')
      const key = t.length > 2 ? t[t.length - 2] || '' : ''
      const name = t[t.length - 1]
      if (name.indexOf('request-') === 0) {
        asyncAction.inc(key)
      } else if (name.indexOf('success-') === 0 || name.indexOf('failure-') === 0 || name.indexOf('cancel-') === 0) {
        asyncAction.dec(key)
      } else {
        return
      }
    }
  }
}

type AsyncActionContext = {
  inc: (name: string) => void
  dec: (name: string) => void
  usePending: (name: string) => boolean
}

export const usePending = (name?: string): boolean =>
  useContext<AsyncActionContext>(asyncActionContext).usePending(name || '')

const asyncActionContext = createContext<AsyncActionContext>(undefined as any)
const Provider = asyncActionContext.Provider

export const AsyncActionProvider = (props: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(reducer, {})
  const context = {
    inc(name: string) {
      dispatch(inc(name))
    },
    dec(name: string) {
      dispatch(dec(name))
    },
    usePending(name?: string) {
      return !!state[name || '']
    }
  }
  return (
    <Provider value={context}>{props.children}</Provider>
  )
}

export const useAsyncAction = () =>
  useContext<AsyncActionContext>(asyncActionContext)
