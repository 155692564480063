import React, { useCallback, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import logo from './assets/mm.png'
import { AuthState } from './store/state'
import { selectSystem } from './store/actions'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  list: {
    margin: theme.spacing(0, 2),
  },
  description: {
    display: 'flex',
  },
  text: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  key: {
  },
}))

const validSystemVersion = (versionNo: string | null) => {
  if (versionNo === null) {
    return false
  }
  const major = Number(versionNo.split('.')[0])
  const minor = Number(versionNo.split('.')[1])

  if(!isNaN(major) && major >= 10) {
    return true
  }
  if (isNaN(major) || major < 8) {
    return false
  }
  if (isNaN(minor) || minor < 11) {
    return false
  }
  return true
}

export default () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const systems = useSelector<AuthState, any[]>(state => state.systems)
  const doSelect = useCallback(
    (system: any) => dispatch(selectSystem.request(system)),
    [dispatch]
  )

  useEffect(() => {
    if (systems.length === 1) {
      doSelect(systems[0])
    }
  }, [systems, doSelect])

  return (
    <div>
      <h4 style={{ textAlign: 'center' }}>
        <FormattedMessage id='Select {MaintMaster} {System}¤' />
      </h4>
      <List
        component='nav'
        aria-label='main system-list'
        className={classes.list}
        style={{ overflow: 'auto',
          maxHeight: 'calc(65vh - 250px)',
          marginBottom: '16px',
          border: '1px solid #dedede',
          borderRadius: '12px'
        }}
      >
        {systems.map((s) => (
          <ListItem
            key={s.Key}
            button
            onClick={() => doSelect(s)}
            disabled={!validSystemVersion(s.Version)}
          >
            <ListItemIcon>
              <img src={logo} width={32} height={32} alt='system icon' />
            </ListItemIcon>
            <ListItemText primary={s.Name} secondary={<span className={classes.description}><span className={classes.text}>{s.Description}</span><span className={classes.key}>({s.Key})</span></span>} />
          </ListItem>
        ))}
      </List>
    </div>
  )
}
